<template>
    <div>
        <div class="mb-4">For
            <ChargeTypeStatus :billingReport="billingReport" /> billing,
            here are the list of <strong>Delayed Transfer</strong> transactions that matured within the billing period
            of <b>{{ startDate }}</b> and <b>{{ endDate }}</b>.
        </div>

        <!-- Select Actions and Items Per Page Options -->
        <b-row class="mt-4 mb-2">
            <b-col sm="6" offset-sm="6" md="4" offset-md="8" class="text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="perPage" />
                </b-input-group>
            </b-col>
        </b-row>
        <b-table ref="delayedTransferTable" show-empty striped hover :items="items" :fields="fields"
            :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc" :sort-direction="sortDirection" responsive>

            <template v-slot:cell(delayedTransferMaturityDate)="row">
                <span class="text-nowrap">{{ getFormattedDate(row.item.delayedTransferMaturityDate) }}</span>
            </template>

            <template v-slot:cell(dateReceived)="row">
                <span class="text-nowrap">{{ getFormattedDate(row.item.dateReceived) }}</span>
            </template>

            <template v-slot:cell(dispatchNo)="row">
                <span class="numFont">
                    {{ row.item.dispatchNo }}
                </span>
            </template>

            <template v-slot:cell(delayedTransferDays)="row">
                <span class="numFont">
                    {{ row.item.delayedTransferDays }}
                </span> days
            </template>


            <template v-slot:cell(quantity)="row">
                <span class="numFont">
                    ({{ row.item.quantity.toLocaleString() }})
                </span>
            </template>

            <template v-slot:cell(calendarDays)="row">
                <span class="numFont">
                    ({{ (-1 * row.item.calendarDays).toLocaleString() }})
                </span>
            </template>

            <template v-slot:cell(totalDays)="row">
                <span class="numFont">
                    ({{ (-1 * row.item.totalDays).toLocaleString() }})
                </span>
            </template>

            <template v-slot:cell(calendarHours)="row">
                <span class="numFont">
                    ({{ (-1 * row.item.calendarHours).toLocaleString() }})
                </span>
            </template>

            <template v-slot:cell(totalHours)="row">
                <span class="numFont">
                    ({{ (-1 * row.item.totalHours).toLocaleString() }})
                </span>
            </template>

        </b-table>
        <b-row>
            <b-col md="8" sm="12">
                <span class="total-display">Total: {{ totalRows ? totalRows.toLocaleString() : 0 }}</span>
            </b-col>
            <b-col md="4" sm="12">
                <b-pagination align="right" :total-rows="totalRows" :per-page="perPage" v-model="currentPage"
                    class="my-0" />
            </b-col>
        </b-row>

    </div>
</template>

<script>
// Components
import ChargeTypeStatus from '../ChargeTypeStatus.vue';

// Utils
import { BillingReportUtil } from '@/utils/billingReportUtil';
import { DateUtil } from '@/utils/dateutil';
import { DispatchUtil } from '@/utils/dispatchUtil';

// DAO
import dispatchDAO from '@/database/dispatches';

// Others
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import truncate from 'vue-truncate-collapsed';
import _ from 'lodash';


export default {
    name: 'billing-delayed-transfer-tab',
    components: {
        ChargeTypeStatus,
        Loading,
        truncate
    },
    props: {
        clientAccount: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            items: [],
            fields: [],
            defaultFields: [
                {
                    key: 'dateReceived',
                    label: 'Date Received',
                    sortable: false,
                },
                {
                    key: 'delayedTransferMaturityDate',
                    label: 'Maturity Date',
                    sortable: false,
                },
                {
                    key: 'dispatchNo',
                    label: 'Dispatch No.',
                    sortable: false,
                },
                {
                    key: 'Source',
                    label: 'Source',
                    sortable: false,
                },
                {
                    key: 'Destination',
                    label: 'Destination',
                    sortable: false,
                },
                {
                    key: 'delayedTransferDays',
                    label: 'Delayed Transfer Days',
                    sortable: false,
                },
                {
                    key: 'quantity',
                    sortable: false,
                    class: 'text-right'
                }
            ],
            currentPage: 1,
            perPage: 5,
            totalRows: 0,
            pageOptions: [5, 10, 15, 25, 50, 100],
            sortBy: null,
            sortDesc: false,
            sortDirection: 'asc',
            filter: null,

            billingReport: {},
            dispatches: {},
            totalDays: 0,
            totalHours: 0,

            // Check for loader
            isLoading: false,
        }
    },
    computed: {
        contractStartDate() {
            return this.clientAccount && this.clientAccount.startDate ? this.clientAccount.startDate : 0;
        },
        startDate() {
            return DateUtil.getFormattedDate(this.billingReport.startDate);
        },
        endDate() {
            return DateUtil.getFormattedDate(this.billingReport.endDate);
        },
        billingReportNo() {
            return this.billingReport && this.billingReport.billingReportNo ? this.billingReport.billingReportNo : '';
        },
        assetOwnerId() {
            return this.billingReport && this.billingReport.assetOwnerId ? this.billingReport.assetOwnerId : '';
        },
        clientId() {
            return this.billingReport && this.billingReport.clientId ? this.billingReport.clientId : '';
        },
        clientAccountId() {
            return this.billingReport && this.billingReport.clientAccountId ? this.billingReport.clientAccountId : '';
        },
        chargeTypeDetails() {
            return this.billingReport && this.billingReport.chargeTypeDetails ? this.billingReport.chargeTypeDetails : {};
        },
        assetTypeId() {
            return this.chargeTypeDetails.assetTypeId ? this.chargeTypeDetails.assetTypeId : '';
        },
        rentFrequency() {
            return this.chargeTypeDetails.rentFrequency ? this.chargeTypeDetails.rentFrequency : '';
        },
    },
    async mounted() {
        // init billingReport
        this.billingReport = { ...this.$store.getters.currBillingReport };
        await this.onReset(this.billingReport);

        EventBus.$on("onLoadDelayedTransfers", async billingReport => {
            await this.onReset(billingReport);
        });
    },
    methods: {
        async onReset(billingReport) {
            this.billingReport = billingReport;
            if (this.billingReport && !_.isEmpty(this.billingReport)) {
                this.updateTableFields();
                await this.retrieveTransactions();
            }
        },
        updateTableFields() {
            this.fields = [...this.defaultFields];

            if (this.rentFrequency === 'Per Day') {
                this.fields.push({
                    key: 'calendarDays',
                    label: 'Calendar Days',
                    sortable: false,
                    class: 'text-right'
                });
                this.fields.push({
                    key: 'totalDays',
                    label: 'Total Days',
                    sortable: false,
                    class: 'text-right'
                });
            } else if (this.rentFrequency === 'Per Hour') {
                this.fields.push({
                    key: 'calendarHours',
                    label: 'Hours',
                    sortable: false,
                    class: 'text-right'
                });
                this.fields.push({
                    key: 'totalHours',
                    label: 'Total Hours',
                    sortable: false,
                    class: 'text-right'
                });
            }

            // refresh table
            if (this.$refs.rentalTransactionsTable) {
                this.$refs.rentalTransactionsTable.refresh();
            }
        },
        async retrieveTransactions() {
            try {
                // show loading indicator
                this.isLoading = true;

                let filter = {
                    status: 'Received',
                    isDelayedTransfer: true,
                    maturityStartDate: this.billingReport.startDate,
                    maturityEndDate: this.billingReport.endDate,
                    accountNo: { id: this.clientAccountId },
                    sourceCompany: { id: this.clientId }
                };
                this.dispatches = await dispatchDAO.getDispatches(filter);
                this.dispatches = _.filter(this.dispatches, dispatch => {
                    // Include only dispatch with assetTypeId and the destination is not the asset owner
                    let isIncluded = false;
                    let dispatchAsset = this.getDispatchAsset(dispatch, this.assetTypeId);
                    if (dispatchAsset && !_.isEmpty(dispatchAsset)) {
                        isIncluded = true;
                    }
                    return dispatch.destination.companyId !== this.assetOwnerId && isIncluded;
                });

                this.processDispatches(this.dispatches);
            } catch (error) {
                this.$toaster.error('Error loading transactions. Please reload the page again.');
            } finally {
                // hide loading indicator
                this.isLoading = false;
            }
        },
        getDispatchAsset(dispatch, assetTypeId) {
            return _.find(dispatch.assets, o => {
                return o.assetTypeId === assetTypeId;
            });
        },

        processDispatches(dispatches) {
            // reset
            this.items = [];

            _.forEach(dispatches, dispatch => {
                let item = { ...dispatch };

                item['Source'] = DispatchUtil.getCompanyLocationDisplay(item.source);
                item['Destination'] = DispatchUtil.getCompanyLocationDisplay(item.destination);

                let dispatchAsset = this.getDispatchAsset(dispatch, this.assetTypeId);
                item.quantity = dispatchAsset.actualQuantity;

                if (this.rentFrequency === 'Per Day') {
                    let calendarDays = BillingReportUtil.getCalendarDays(item.delayedTransferMaturityDate, this.endDate);
                    item.calendarDays = calendarDays * -1;

                    item.totalDays = BillingReportUtil.getTotalDays(item.calendarDays, item.quantity);
                } else if (this.rentFrequency === 'Per Hour') {
                    let calendarHours = BillingReportUtil.getCalendarHours(item.delayedTransferMaturityDate, this.endDate);
                    item.calendarHours = calendarHours * -1;

                    item.totalHours = BillingReportUtil.getTotalHours(item.calendarHours, item.quantity);
                }

                this.items.push(item);
            });
            this.totalRows = this.items.length;

            // sort by ascending order
            this.items = _.sortBy(this.items, ['dateReceived']);
            // refresh table
            if (this.$refs.delayedTransferTable) {
                this.$refs.delayedTransferTable.refresh();
            }

            // update computation
            EventBus.$emit('onUpdateComputationFromDelayedTransfers', this.items);
        },

        // UTILS
        getFormattedDate(date) {
            return DateUtil.getFormattedDate(date);
        },
    },
    beforeDestroy() {
        EventBus.$off('onLoadDelayedTransfers');
    },
}
</script>

<style scoped></style>
